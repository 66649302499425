import React from 'react';

import Form from '../../components/Form';

import { __ } from '../../i18n';

export default class TagForm extends Form {
  defaultValues = {
    name: '',
    checked: false
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
  }

  maybes = {
    name: ['required', values => this.validateContent('name', values)],
  }

  validateContent = (name, values) => {
    if (name === 'name') {
      return values.trim().length > 0;
    }

    return true;
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="TagForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Input
          label={__('Name')}
          name="name"
          value={values.name}
          onChange={this.onInputChange}
          error={errors.name}
        />
        <Form.Checkbox
          label={__('Initially checked')}
          name="checked"
          data-value={!!values.checked}
          checked={!!values.checked}
          onClick={() => this.onInputChange(null, { name: 'checked', checked: !values.checked })}
        />
      </Form>
    );
  }
}
