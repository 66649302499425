import React from 'react';
import { Accordion, Container, Segment, Icon } from 'semantic-ui-react';
import Form from '../../components/Form';
import { inject } from 'mobx-react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { __ } from '../../i18n';
import { hasNetwork } from '../../app/helpers/hasNetwork';

import Loading from '../../components/ui/Loading';

import { MarkersDropdown } from './components/MarkersDropdown';

const markersListQuery = gql`
  query MarkersListQuery(
    $organizationId: ID!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    organizationMarkers(
      organizationId: $organizationId
      filters: { name: $search }
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`;

const MARKERS_LIMIT = 30;

let timeoutId;

@inject('store')
@graphql(markersListQuery, {
  options: (ownProps) => ({
    fetchPolicy: 'network-only',
    variables: {
      organizationId: ownProps.store.currentOrganization.id,
      search: '',
      limit: MARKERS_LIMIT,
      offset: 0,
    },
    skip: !hasNetwork(ownProps.store.currentOrganization),
  }),
})
export default class GroupForm extends Form {
  defaultValues = {
    name: '',
    internal: false,
    seeAll: false,
    type: null,
    markerIds: [],
    isLoadingMore: false,
    searchQuery: '',
  };

  onLoadMore = () => {
    this.setState({ isLoadingMore: true });
    const { data } = this.props;
    const { fetchMore } = data;

    fetchMore({
      variables: {
        offset: data.organizationMarkers.nodes.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        this.setState({ isLoadingMore: false });

        return {
          organizationMarkers: {
            nodes: [
              ...prev.organizationMarkers.nodes,
              ...fetchMoreResult.organizationMarkers.nodes,
            ],
            totalCount: fetchMoreResult.organizationMarkers.totalCount,
            __typename: prev.organizationMarkers.__typename,
          },
        };
      },
    });
  };

  onSearchChange = async (e, value) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.setState({ isLoadingMore: true, searchQuery: value.searchQuery });

    timeoutId = setTimeout(async () => {
      const { data } = this.props;
      const { refetch } = data;

      await refetch({
        limit: MARKERS_LIMIT,
        offset: 0,
        search: value.searchQuery,
      });

      this.setState({ isLoadingMore: false });
    }, 500);
  };

  onClearSearch = () => {
    this.onSearchChange(null, { searchQuery: '' });
  };

  render() {
    const { values, advanced, isLoadingMore } = this.state;
    const { data } = this.props;
    const { unleash, currentOrganization } = this.props.store;

    const hasNetworkFeature = hasNetwork(currentOrganization);

    if ((data && data.loading) || (unleash && !unleash.isReady)) {
      return (
        <Form
          id="GroupForm"
          disableSubmit
          {...this.props}
          onSubmit={this.handleSubmit}
        >
          <Loading style={{ marginBottom: '4rem' }} width={32} height={32} />
        </Form>
      );
    }

    const isNameDisabled = values.name.trim().length === 0;

    const isMarkersIdsDisabled = hasNetworkFeature
      ? values.markerIds && values.markerIds.length === 0
      : false;

    return (
      <Form
        id="GroupForm"
        {...this.props}
        onSubmit={this.handleSubmit}
        disableSubmit={isNameDisabled || isMarkersIdsDisabled}
      >
        <Form.Input
          label={`${__('Group name')} *`}
          placeholder={__('Group name')}
          name="name"
          value={values.name}
          onChange={this.onInputChange}
          autoFocus
          maxLength={255}
        />
        {hasNetworkFeature && (
          <MarkersDropdown
            data-testid="markers-dropdown"
            name="markerIds"
            value={values.markerIds}
            onSearchChange={this.onSearchChange}
            onChange={(e, prop) =>
              this.onSelectionChange(e, prop, () => this.onClearSearch())
            }
            loading={isLoadingMore}
            onLoadMore={this.onLoadMore}
            options={data.organizationMarkers.nodes.map((marker) => ({
              value: marker.id,
              text: marker.name,
            }))}
          />
        )}
        <Accordion>
          <Accordion.Title
            active={advanced}
            onClick={() => this.setState({ advanced: !advanced })}
          >
            <Icon name="dropdown" />
            {__('Advanced')}
          </Accordion.Title>
          <Accordion.Content className="ui secondary segment" active={advanced}>
            <Segment secondary>
              <Container textAlign="left">
                <Form.Checkbox
                  label={__('For internal communication')}
                  name="internal"
                  checked={values.internal}
                  onClick={() =>
                    this.onInputChange(null, {
                      name: 'internal',
                      checked: !values.internal,
                    })
                  }
                />
                <p style={{ marginBottom: '1rem' }}>
                  {__('Staff can send message to other staff in the group')}
                </p>
                <Form.Checkbox
                  label={__('Visible to all staff')}
                  name="seeAll"
                  checked={values.seeAll}
                  onClick={() =>
                    this.onInputChange(null, {
                      name: 'seeAll',
                      checked: !values.seeAll,
                    })
                  }
                />
                <p>
                  {__(
                    'All staff members can send messages to this group, even if they are not in the group',
                  )}
                </p>
              </Container>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </Form>
    );
  }
}
