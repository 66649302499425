import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import TagForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query TagEditQuery ($id: ID!) {
    node(id: $id) @connection(key: "Tag", filter: ["id"]) {
      ... on Tag {
        id: dbId
        name
        checked
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.tag_id
    }
  })
})
@graphql(gql`mutation updateTag($updateTagMutation: UpdateTagInput!) {
  updateTag(input: $updateTagMutation) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery', 'TagEditQuery']
  }
})
@observer
export default class TagEdit extends Controller {
  request = values => this.props.mutate({
    variables: {
      updateTagMutation: {
        id: this.props.params.tag_id,
        ...values
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Tag %s edited successfully', values.name), success: true };
    this.props.router.goBack();
  })

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, checked } = data.node;

    return (
      <Controller
        id="TagEdit"
        modal={{ closeOnRootNodeClick: false }}
        edit
        title={__('Edit tag')}
        form={TagForm}
        values={{
          name,
          checked
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
